.index-page-layout-v1 {
	margin-top: var(--page-margin-top);

	h2 {
		text-align: center;
	}

	.bg-light-blue {
		background-color: rgb(78 113 231 / 10%);
	}

	.subtitle {
		text-align: center;
		text-indent: 0px;
	}

	.index-map {
		.leaflet-popup-content {
			margin: 0;
			padding: 15px;
		}
	}

	.highlights {
		display: flex;
		justify-content: space-between;

		@media (max-width:992px) {
			flex-wrap: wrap;
		}


		.highlight {
			text-align: center;
			color: var(--color-black);
			padding: 10px;
			position: relative;

			.icon {
				margin-bottom: 10px;
				margin-top: 20px;
				width: 45px;
				height: 45px;
				display: inline-block;
				background-size: contain;
				background-repeat: no-repeat;
				filter: brightness(0) saturate(100%) invert(16%) sepia(76%) saturate(2107%) hue-rotate(185deg) brightness(89%) contrast(101%);

				&.icon-headset {
					background-image: url(RESOURCE/img/icon-headset.svg);
				}

				&.icon-house-building {
					background-image: url(RESOURCE/img/icon-house-building.svg);
				}

				&.icon-person-walking-luggage {
					background-image: url(RESOURCE/img/icon-person-walking-luggage.svg);
				}
			}

			.title {
				font-size: 20px;
				margin-bottom: 10px;
				font-weight: 600;
			}

			.text {
				font-size: 18px;
				padding-bottom: 30px;
				margin: 0 auto;

				@media(max-width: 1023px) {
					max-width: 300px;
				}

			}
		}
	}

}