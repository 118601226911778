.unit-pricelist-v1 {

	.nav-link {
		border: 1px solid var(--color-grey-normal);
		border-bottom: none;
		background-color: var(--color-grey-light);
		color: var(--font-color-main);
		text-decoration: none;

		&.active {
			background-color: var(--color-white);
			border: 1px solid var(--color-grey-dark);
			border-bottom: none;
		}
	}

	.nav-item {
		margin-right: 5px;
	}

	.hint {
		margin: 25px 0px;
	}

	.pricelist-table {

		sup {
			margin-left: -3px;
		}

		td {
			vertical-align: middle;

			@media (max-width:460px) {
				font-size: 0.8rem;
			}


		}

		.mainrow {
			background-color: var(--color-grey-light);
		}

		.subrow {

			.subinfo {
				padding-left: 20px;

				@media (max-width:460px) {
					padding-left: 10px;
				}
			}

			td {
				font-size: 0.9rem;

				@media (max-width:460px) {
					font-size: 0.8rem;
				}


			}

			.price {
				font-size: 1rem;

				@media (max-width:460px) {
					font-size: 0.8rem;
				}
			}


		}
	}
}