.unit-description-v1 {
	.description-block {
		margin-bottom: 40px;
		text-align: justify;

		.d-title {
			padding-bottom: 10px;
			font-family: var(--font-family-main);
		}
	}
}